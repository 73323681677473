import stjsymbol from'../Image/stjit2.png';
function Second(){
    return(<div className='second'>
            <img alt='' src={stjsymbol} className='stjsymbol'></img>
            <div className='home'>home</div>
            <div className='home2'>About
                <select className='About'>
                    <option>

                        </option></select></div>
            <div className='home1'>Admission</div>
            <div className='home1'>Department</div>
            <div className='home1'>Placement</div>
            <div className='home1'>facilities</div>
            </div>
        );
}
export default Second;