function Ourstr(){
    return(
    <div>
        <p className="our">our strength</p>
        <div className="Our">
        <div className="a1">
        <p className="ourtext">6</p>
        <p className="ourtext1">UG programmes</p>
        </div>
        <div className="a2">
        <p className="ourtext">2000</p>
        <p className="ourtext1">Students</p>
        </div>
        <div className="a2">
        <p className="ourtext">135</p>
        <p className="ourtext1">Teachers</p>
        </div>
        <div className="a2">
        <p className="ourtext">186+</p>
        <p className="ourtext1">Pacements</p>
        </div>
        </div>
    </div>);
}
export default Ourstr;